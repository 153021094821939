import { NewsCard } from '@/components/generic/NewsCard'
import Pagination from '@/components/generic/Pagination'
import SectionTitleWithLine from '@/components/generic/SectionTitleWithLine'
import Layout from '@/components/layouts/Layout'
import { Container } from '@/components/styled/Container'
import InnerContainer from '@/components/styled/InnerContainer'
import { language } from '@/helpers/Language'
import { FormatDate } from '@/utils/dates/DateFormatter'
import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import React from 'react'
import StyledLink from '@/components/styled/Link'
import { useTranslation } from 'react-i18next'
import { Categories, Category, NewsBar, NewsWrapper } from '../NewsIndex'

export default function Announcement({ data, pageContext }) {
  if (!data) {
    return <div>Loading news...</div>
  }

  const { t } = useTranslation()

  return (
    <Layout
      pageTitle={t('seo.news.title')}
      pageDescription={t('seo.news.meta_description')}
      pageCanonical="https://ichigo.com/news/announcement"
    >
      <NewsBar>
        <Container
          style={{ padding: '0 96px' }}
          mobileStyle={css`
            padding: 0 16px !important;
          `}
        >
          <InnerContainer style={{ flexDirection: 'column', margin: '0 auto' }}>
            <Categories>
              <StyledLink to="/news">
                <Category>{t('news_category.all-news')}</Category>
              </StyledLink>
              <StyledLink to="/news/press-release">
                <Category>{t('news_category.press-release')}</Category>
              </StyledLink>
              <StyledLink to="/news/media-coverage">
                <Category>{t('news_category.media-coverage')}</Category>
              </StyledLink>
              <Category className="active">
                {t('news_category.announcement')}
              </Category>
            </Categories>
          </InnerContainer>
        </Container>
      </NewsBar>

      <Container
        style={{ padding: '96px', paddingTop: '168px' }}
        mobileStyle={css`
          padding: 72px 16px !important;
          padding-top: 120px !important;
        `}
      >
        <InnerContainer
          style={{
            flexDirection: 'column',
            margin: '0 auto',
            position: 'relative'
          }}
        >
          <SectionTitleWithLine
            text="LATEST NEWS"
            secondText="最新ニュース"
            gap="32px"
            centeredOnMobile
            bigTextOnMobile
          />

          <NewsWrapper>
            {data.allPrismicNew.edges.map(({ node }) => {
              const articleLang = node.data.original_language?.toLowerCase()

              return (
                <NewsCard
                  key={node.id}
                  author={
                    language() === articleLang
                      ? node.data.heading?.text
                      : node.data.translated_heading?.text ||
                        node.data.heading.text
                  }
                  category={node.data.category}
                  heading={
                    language() === articleLang
                      ? node.data.subheading?.text
                      : node.data.translated_subheading?.text
                  }
                  date={FormatDate(node.data.date)}
                  link={node.data.link?.url}
                  originalLang={node.data.original_language}
                  slug={node.data.slug}
                  imageUrl={node.data.image?.url}
                  isInternalArticle={node.data.is_internal_article}
                />
              )
            })}
          </NewsWrapper>

          <Pagination
            currentPage={pageContext.currentPage}
            totalPages={pageContext.numPages}
            pages={pageContext.pages}
            baseUrl="/news/announcement"
          />
        </InnerContainer>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicNew(
      filter: { data: { category: { eq: "announcement" } } }
      limit: 5
      sort: { fields: [data___article_date], order: DESC }
    ) {
      edges {
        node {
          id
          data {
            image {
              url
            }
            heading {
              text
            }
            translated_heading {
              text
            }
            subheading {
              text
            }
            translated_subheading {
              text
            }
            is_internal_article
            category
            original_language
            slug
            date
            excerpt {
              text
            }
            link {
              url
            }
          }
        }
      }
    }
  }
`
